<template>
  <div class="page-wrapper member-wrap">
    <div class="container-fluid">
      <div class="page-title">
        <h1 v-if="this.type=='direct'">View Members - {{ repName + "(Rep)" }}</h1>
        <h1 v-else>View Downline Members</h1>
        <button class="clear-search" @click="clearData">Clear Search</button>
      </div>
      <client-search :filters="filterQuery" @onSearch="filterData" :type="this.type" />
      <div class="row member-card member-card-list">
        <div class="clientsData-wrap" ref="memberListing">
          <div class="top-pagination">
            <div class="d-flex justify-content-between align-items-center">
            <page-result :rows.sync="memberLists" />
            <pagination :rows.sync="memberLists" :filters="filterQuery" />
            </div>
          </div>
          <clients-data :memberLists="memberLists" />
          <div class="d-flex justify-content-between align-items-center">
            <page-result :rows.sync="memberLists" />
            <pagination :rows.sync="memberLists" :filters="filterQuery" />
            </div>
         
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import axios from "axios";
import Helper from "@/resource/Helper";
import ClientsData from "@/views/MemberList/partials/ClientsData.vue";
import ClientSearch from "@/views/MemberList/partials/ClientSearch.vue";

const filterData = {
  first_name: "",
  last_name: "",
  status: "",
  enrollment_date: "",
  state: "",
  effective_date: "",
  enrollment_from: "",
  enrollment_to: "",
  rep_id: "",
  group_id: "",
  type:''
};
export default {
  name: "MemberListByRep",
  components: {
    ClientSearch,
    ClientsData,
  },
  data: () => ({
    filterQuery: { ...filterData },
    memberLists: [],
    repName: "",
    type: "",
  }),
  mounted() {
    this.filterQuery.rep_id = parseInt(atob(this.$route.params.repId));
    this.filterQuery.type=this.type
    this.getData(this.filterQuery);
  },
  created() {
    this.type = this.$route.params.type;
  },
  methods: {
    clearData() {
      this.filterQuery = { ...filterData };
      this.filterQuery.rep_id = parseInt(atob(this.$route.params.repId));
      this.getData(this.filterQuery);
    },
    filterData(filters) {
      this.filterQuery = filters;
      this.getData(this.filterQuery);
    },
    async getData(filters = []) {
      let url;
      let filterUrl = Helper.objToUrlParams(filters);
      url = this.$endpoint.GET_MEMBERS + `?${filterUrl}`;
      let loader = this.$loading.show();
      await axios
        .get(url)
        .then((res) => {
          this.memberLists = res.data;
          this.repName = `${res.data.records[0].rep_first_name} ${res.data.records[0].rep_last_name}`;
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>
<style src="@/assets/css/viewclients.css"></style>
